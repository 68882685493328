@import "@openedx/paragon/scss/core/core";

.review-modal-body {
  background-color: $gray-300 !important;
  padding: inherit;

  & > div.pgn__modal-body-content {
    .row {
      height: 100%;
    }
  }

  .content-block {
    width: fit-content;
    margin: auto;
    height: 100%;
  }

  .content-wrapper {
    width: min-content;
  }
}

@include media-breakpoint-down(sm) {
  .review-modal-body {
    padding: 0 !important;
    overflow: hidden !important;

    & > div.pgn__modal-body-content {
      overflow-y: scroll;
    }

    .content-block .col {
      padding: 0;
    }

    .content-wrapper {
      width: 100%;
    }
  }
}